const defaultLocale = 'nb-NO';
const availableLocales = ['en-GB', 'nb-NO']; // Update webpackInclude in main.js to match all available locales
let languagePreference: string | undefined; // For caching and getting all usages in sync

export function getLocalePreference(languageCode?: string): string {
  try {
    languageCode = languageCode && languageCode.toLowerCase();

    const languageOverride = getLanguageOverride();
    if (languageOverride) {
      languagePreference = languageOverride;
      return languagePreference;
    }

    if (languagePreference) {
      return languagePreference;
    }

    languagePreference = getFromAvailableLocales(languageCode);
    if (languagePreference) {
      return languagePreference;
    }

    languagePreference = getFromAvailableLocales(getBrowserLanguagePreference());
    if (languagePreference) {
      return languagePreference;
    }

    languagePreference = defaultLocale;
  } catch (e) {
    languagePreference = defaultLocale;
  }
  return languagePreference;
}

function getBrowserLanguagePreference(): string | undefined {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return undefined;
  }

  const wn = window.navigator;

  if (wn.languages) {
    for (const lang of wn.languages) {
      const availablePreferredLocale = getFromAvailableLocales(lang);
      if (availablePreferredLocale) {
        return availablePreferredLocale;
      }
    }
  }

  const availablePreferredLocale = getFromAvailableLocales(wn.language);
  if (availablePreferredLocale) {
    return availablePreferredLocale;
  }

  return undefined;
}

export function getAngularFileLocaleName(): string | undefined {
  const languageCode = getLocalePreference();
  switch (languageCode) {
    case 'nb-NO':
      return 'nb';
    default:
      return languageCode;
  }
}

// exported for testing
export function getFromAvailableLocales(localeCode: string | undefined): string | undefined {
  if (!localeCode || typeof localeCode !== 'string') {
    return undefined;
  }

  return availableLocales.find((item) => item.toLowerCase().includes(localeCode.split('-')[0]));
}

// Language override
export function changeLanguage(): void {
  let languageIndex = languagePreference ? availableLocales.indexOf(languagePreference) : 0;
  languageIndex = (languageIndex + 1) % availableLocales.length;

  setLanguageOverride(availableLocales[languageIndex]);
}

function setLanguageOverride(languageCode: string): void {
  sessionStorage.setItem('languageOverride', languageCode);
}

function getLanguageOverride(): string | null {
  return sessionStorage.getItem('languageOverride');
}
